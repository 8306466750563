<template>
    <div>
        <div class="noteEntryError">{{ noteError }}</div>
        <div class="noteEntryContainer">
            <b-form-group
                class="noteEntry"
                label="Exécution"
            >
                <b-form-input
                    class="noteEntry"
                    v-model="noteEntryE"
                    type="number"
                    @keypress="noteE_keypress"
                    @input="noteE_input"
                    ref="executionNote" />
            </b-form-group>
            <b-form-group
                class="noteEntry"
                label="Contenu"
            >
                <b-form-input
                    class="noteEntry"
                    v-model="noteEntryC"
                    type="number"
                    @keypress="noteC_keypress"
                    @input="noteC_input"
                    ref="contentNote" />
            </b-form-group>
        </div>
        <b-button
            class="endNoteEntry cancel"
            size="sm"
            variant="secondary"
            @click="cancel_click"
        >
            {{ disabled ? '&lt;&lt; Retour' : '&lt;&lt; Annuler' }}
        </b-button>
        <b-button
            v-if='!disabled'
            class="endNoteEntry validate"
            size="sm"
            :variant="noteIsValid ? 'primary' : 'danger'"
            :disabled="!noteIsValid"
            @click="validate_click"
        >
            Valider &gt;&gt;
        </b-button>

        <b-modal
            title="Confirmation de saisie"
            centered
            :static="true"
            :lazy="true"
            size="sm"
            v-if="cfgConfirmNoteEntryByPopup"
            v-model="showConfirmationModal"
            ok-variant="success"
            ok-title="Oui"
            cancel-variant="warning"
            cancel-title="non, je veux corriger mes notes"
            button-size="lg"
            >
            <template #modal-footer="{cancel}">
                <b-button size="sm" variant="warning" @click="cancel()">Non, je veux corriger une note</b-button>
                <b-button size="lg" variant="success" @click="validate_click">Oui</b-button>
            </template>
            Vous avez saisi les notes suivantes
            <div class="noteEntryContainer">
                <div class="noteCheck"><div>Execution</div>{{ noteEntryE }}</div>
                <div class="noteCheck"><div>Contenu</div>{{ noteEntryC }}</div>
            </div>
            <div>confirmez-vous ces notes ?</div>
            <i class="warning">ATTENTION ! Une fois confirmée, votre note ne pourra plus être modifiée</i>

        </b-modal>
    </div>
</template>

<script>

export default {
    props : {
        disabled:{type:Boolean, default:false,},
        executionMinValue: { type: Number, default: 0 },
        executionMaxValue: { type: Number, default: 100 },
        executionPound : { type: Number, default: 6},
        contentMinValue: { type: Number, default: 0 },
        contentMaxValue: { type: Number, default: 100 },
        contentPound: {type: Number, default: 4},
        execution: { type: Number, default: null},
        content: { type: Number, default: null},
    },
    data(){
        return {
            previousEntryE: this.execution,
            previousEntryC: this.content,
            noteEntryE: this.execution,
            noteEntryC: this.content,
            noteError: '',
            showConfirmationModal: false,
        };
    },
    computed:{
        noteIsValid(){
            return (this.noteEntryE != null && this.noteEntryE != "") && (this.noteEntryC != null && this.noteEntryC != "") && (this.noteError == null || this.noteError == "");
        },
    },
    methods:{
        noteE_keypress(e){
            this.noteError = '';
            var regExp = new RegExp ('[0123456789]');
            if(!regExp.test(e.key))
            {
              if(e.key == 'Enter'){
                if(this.noteEntryC == null || this.noteEntryC == ''){
                  this.$refs.noteEntryC.focus();
                }else{
                  this.validate_click();
                }
              } else {
                e.preventDefault();
                this.noteError = "La note d'exécution doit être un nombre entier (sans virgule) compris entre " + this.executionMinValue + " et " + this.executionMaxValue + ".";
              }
            }
        },
        noteC_keypress(e){
            this.noteError = '';
            var regExp = new RegExp ('[0123456789]');
            if(!regExp.test(e.key))
            {
              if(e.key == 'Enter'){
                if(this.noteEntryE == null || this.noteEntryE == ''){
                  this.$refs.noteEntryE.focus();
                }else{
                  this.validate_click();
                }
              } else {
                e.preventDefault();
                this.noteError = "La note de contenu doit être un nombre entier (sans virgule) compris entre " + this.contentMinValue + " et " + this.contentMaxValue + ".";
              }
            }
        },
        noteE_input(){
            this.noteError = '';
            if(this.noteEntryE != null && this.noteEntryE != "")
            {
                var numEntry = parseInt(this.noteEntryE);
                if(numEntry < this.executionMinValue || numEntry > this.executionMaxValue){
                    this.noteError = "La note d'exécution doit être comprise entre " + this.executionMinValue + " et " + this.executionMaxValue + ".";
                    this.$nextTick(()=> {
                        this.noteEntryE = this.previousEntryE;
                    });
                }
                else
                {
                    this.previousEntryE = this.noteEntryE;
                }
            }
        },
        noteC_input(){
            this.noteError = '';
            if(this.noteEntryC != null && this.noteEntryC != "")
            {
                var numEntry = parseInt(this.noteEntryC);
                if(numEntry < this.contentMinValue || numEntry > this.contentMaxValue){
                    this.noteError = "La note de contenu doit être comprise entre " + this.contentMinValue + " et " + this.contentMaxValue + ".";
                    this.$nextTick(()=> {
                        this.noteEntryC = this.previousEntryC;
                    });
                }
                else
                {
                    this.previousEntryC = this.noteEntryC;
                }
            }
       },
        validate_click(){
            if(this.cfgConfirmNoteEntryByPopup && !this.showConfirmationModal){
                this.showConfirmationModal = true;
                return true;
            }
            var numEntryE = parseInt(this.noteEntryE);
            var numEntryC = parseInt(this.noteEntryC);
            var total = ((numEntryE * this.executionPound) + (numEntryC * this.contentPound)); /// (this.executionPound + this.contentPound);
            this.$emit('change', { execution: numEntryE, content: numEntryC, total: total });
        },
        cancel_click(){
            this.$emit('cancel');
        },
        focus(){
            this.$refs.executionNote.focus();
        }
    },
    mounted(){
        this.focus();
    }
}
</script>

<style scoped>
    modal-body{
        display: flex 1 1;
        justify-content: center;
        align-items: center;
    }
    i.warning{
        color: var(--danger);
        font-size: 0.8em;
    }
</style>
